<script setup lang="ts">
const emit = defineEmits<{
  mouseenter: [item?: PostItem];
  mouseleave: [item?: PostItem];
}>();

const props = withDefaults(
  defineProps<{
    items: (GridItem | "loading")[];
    columns?: number;
    container?: boolean;
    title?: string;
    type?: PostType;
    loading?: boolean;
  }>(),
  {
    items: () => [],
    columns: 4,
    container: false,
  }
);

const { isMobile, useRemValue } = useUserDevice();
const columns = computed(() => (isMobile.value ? 1 : props.columns));

const gap = useRemValue(2.5);

const { getPostTypeTitlePlural } = usePostTypes();

const heights = [250, 400, 350, 200, 300, 500, 450];

const items = computed(() => {
  if (props.items.length === 0 && props.loading) {
    return Array.from<"loading">({ length: 8 }).map(() => "loading");
  } else {
    return props.items;
  }
});
</script>

<template>
  <section class="post-grid">
    <div class="post-grid__container" :class="{ container }">
      <div class="post-grid__header sr-only">
        <h2 class="post-grid__title">
          {{ title || (type ? getPostTypeTitlePlural(type) : "Wpisy") }}
        </h2>
      </div>

      <template v-if="props.loading || items.length > 0">
        <MasonryWall
          class="post-grid__wrapper grid lg:grid-cols-4 gap-10"
          :items="items"
          :gap="gap"
          :ssr-columns="columns"
          :min-columns="columns"
          :max-columns="columns"
          :key-mapper="(item: GridItem | 'loading', _col: number, _row: number, index: number) => item !== 'loading' ? item?.post?.id || item?.id : index"
        >
          <template #default="{ item, index }">
            <div class="post-grid__item">
              <PostGridLoader
                v-if="item === 'loading'"
                class="-mb-2.5"
                :key="`loading-${index}`"
                :height="heights[index] || 300"
              />

              <PostGridItem
                v-else-if="item?.post"
                :key="item?.post?.id || item?.id || index"
                :item="item"
                :lazy="index > 4"
                title-tag="h3"
                class="-mb-2.5"
                @mouseenter="
                  () => $nextTick(() => emit('mouseenter', item.post))
                "
                @mouseleave="() => emit('mouseleave', item.post)"
              />
            </div>
          </template>
        </MasonryWall>
      </template>

      <template v-else>
        <div class="post-grid__empty lg:col-span-4 my-[5.25rem]">
          <p
            class="post-grid__empty-text flex flex-col items-center justify-center gap-4 lg:block text-center text-lg leading-8 font-semibold"
          >
            <span>Nie znaleziono szukanego wyniku</span>
          </p>
        </div>
      </template>
    </div>
  </section>
</template>
